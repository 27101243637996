import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const Footer = ({ theme, className, page }) => {
  const cls = classNames(theme, className)
  return (
    <footer className={cls}>
      <div className="container">
        <div className="d-flex justify-content-center py-3">
          <ul className="d-flex flex-row px-2">
            <li>
              {page === 'terms' ? (
                <AnchorLink href="#allmäna-användarvillkor">Användarvillkor</AnchorLink>
              ) : (
                <Link to="/general-terms">Användarvillkor</Link>
              )}
            </li>
            <li>
              {page === 'privacy-policy' ? (
                <AnchorLink href="#integritetsnotis-för-healfy">Personuppgiftspolicy</AnchorLink>
              ) : (
                <Link to="/privacy-policy">Personuppgiftspolicy</Link>
              )}
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
