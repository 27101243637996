import React from 'react'
import { Section } from '../components/section'
import Layout from '../components/layout'
import { Header } from '../components/header'
import { Footer } from '../components/footer'

const PrivacyPolicyPage = ({ data }) => (
  <Layout>
    <Header external={true} />
    <Section theme="light" legacy>
      <h1 id="integritetsnotis-för-healfy">INTEGRITETSNOTIS FÖR HEALFY</h1>
      <p>Kära användare,</p>
      <p>
        Healfy är en molnbaserad plattform som erbjuds i tre olika versioner och möjliggör trygg lagring av din hälsodata samt digitala
        hälsoundersökningar mellan dig och en eller flera vårdgivare (”<strong>Tjänsten</strong>”). Plattformen är utvecklad och
        tillhandahålls av Nordic Health Innovation AB, organisationsnummer 556878–3921 med adress Norrberg Hjalmarslund, 923 98 Storuman,
        Sverige (“<strong>vi</strong>” eller “<strong>oss</strong>”).
      </p>
      <p>
        Din integritet är viktig för oss. Enligt allmänna dataskyddsförordningen 2016/679 (”<strong>GDPR</strong>”), ansvarar vi för sådan
        behandling av dina personuppgifter som vi bestämmer syfte och ändamål med. Personuppgifter innefattar information som direkt eller
        indirekt är hänförlig till en levande fysisk identifierbar person, till exempel namn, adress och personnummer. Vi vill med detta
        dokument (”<strong>Integritetnotis</strong>”) redogöra för dig under vilka omständigheter vi är personuppgiftsansvariga för dina
        personuppgifter, hur vi behandlar dina personuppgifter och vilka rättigheter du kan utöva när vi behandlar dina personuppgifter. För
        att använda vår Tjänst måste du först läsa och godkänna denna Integritetsnotis.
      </p>
      <h3>
        PERSONUPPPGIFTER SOM DU REGISTRERAR OCH LAGRAR I VÅR TJÄNST SAMT PERSONUPPGIFTER SOM DU SKICKAR TILL EN VÅRDGIVARE VIA VÅR TJÄNST
      </h3>
      <p>
        Vi är inte personuppgiftsansvariga för personuppgifter som du registrerar och lagrar i vår Tjänst. Om du väljer att dela dina
        personuppgifter med en vårdgivare via vårt Tjänst, är vårdgivaren personuppgiftsansvarig enligt lag för den personuppgiftsbehandling
        som sker mellan dig och vårdgivaren i Tjänsten. I den utsträckning du delar personuppgifter med en vårdgivare via vår Tjänst är vi
        ett personuppgiftsbiträde åt vårdgivaren. Har du frågor eller synpunkter på din registrering och lagring av personuppgifter, eller
        hur en vårdgivare behandlar dina personuppgifter, gäller ej denna Integritetsnotis och i det senare fallet ber vi dig istället att
        kontakta den vårdgivare som du har upprättat en relation med via vår Tjänst.
      </p>
      <h3>PERSONUPPGIFTER SOM VI BEHANDLAR I EGENSKAP AV PERSONUPPGIFTANSVARIG</h3>
      <ol type="a">
        Vi samlar in och behandlar följande personuppgifter i egenskap av personuppgiftsansvarig när du använder våra Tjänst:
        <li>Namn,</li>
        <li>personnummer, </li>
        <li>e-postadress,</li>
        <li>telefonnummer,</li>
        <li>adress,</li>
        <li>företag,</li>
        <li>arbetsroll,</li>
        <li>avdelning,</li>
        <li>IP-adress.</li>
      </ol>
      <p>
        Vänligen notera att vi inte kan tillhandahålla vår Tjänst till dig om du inte tillhandahåller oss dina personuppgifter enligt ovan.
      </p>
      <ol type="a">
        När du använder vår Tjänst kommer viss information om dig att inhämtas automatiskt:
        <li>Information om din användning vår Tjänst, </li>
        <li>
          tekniska data, vilket kan inkludera det URL genom vilket du får åtkomst till vår Tjänst, din IP-adress, unique device-ID,
          information om nätverks- och datorprestanda, typ av webbläsare, språk och information om identifiering samt operativsystem,{' '}
        </li>
        <li>lokaliseringsuppgifter.</li>
      </ol>
      <h3>ÄNDAMÅL OCH LAGLIG GRUND NÄR VI BEHANDLAR DINA PERSONUPPGIFTER I EGENSKAP AV PERSONUPPGIFTSANSVARIG</h3>
      <ol type="a">
        Vi kommer behandla dina personuppgifter för följande ändamål:
        <li value="a">
          För att administrera ditt konto, säkerställa tillhandahållandet av vår Tjänst samt möjliggöra integration med tredje parts
          tjänster och tillhandahålla, personifiera och förbättra din upplevelse av vår Tjänst. Därutöver för att tillhandahålla vår Tjänst
          i enlighet med denna Integritetsnotis och användarvillkoren samt informera dig om uppdateringar av vår Tjänst, denna
          Integritetsnotis eller användarvillkoren. Vår behandling av dina personuppgifter under detta stycke (a) är baserat på ditt
          samtycke,
        </li>
        <li>
          för att förbättra och utveckla vår Tjänst samt skapa nya tjänster och produkter genom att analysera din användning av vår Tjänst.
          Vår behandling av dina personuppgifter under detta stycke (b) är baserat på ditt samtycke,
        </li>
        <li>
          för att säkerställa teknisk funktionalitet hos vår Tjänst, för att förhindra användning av vår Tjänst i strid med denna
          Integritetsnotis och användarvillkoren, för att kunna uppfylla vårt avtal med dig och skydda vår säkerhet, egendom och våra
          rättigheter samt, om nödvändigt, skydda dina och tredje parts intressen samt grundläggande fri- och rättigheter. Vår behandling av
          dina personuppgifter under detta stycke (c) är delvis baserat på vårt fullgörande av det avtal vi ingått med dig om användningen
          av vår Tjänst men är även baserat på ditt samtycke.
        </li>
      </ol>
      <h3>LAGRING AV PERSONUPPGIFTER</h3>
      <p>
        Vi lagrar dina personuppgifter så länge som det är nödvändigt för att kunna tillhandahålla dig vår Tjänst, såvida det inte finns en
        juridisk skyldighet som ålägger oss en annan lagringsperiod för dina personuppgifter.
      </p>
      <h3>ÖVERFÖRING AV PERSONUPPGIFTER</h3>
      <p>
        Vi kan komma att dela med oss av dina personuppgifter till våra partners och leverantörer för att kunna tillhandahålla dig vår
        Tjänst. Du är införstådd med att i den utsträckning en av våra partners eller leverantörer självständigt bestämmer syfte och ändamål
        med de personuppgifter vi delar om dig, kommer en sådan partner eller leverantör att vara personuppgiftsansvarig för sin behandling
        av dina personuppgifter. Ett exempel på en sådan situation är när du legitimerar dig med BankID för att använda vår Tjänst. I en
        sådan situation kommer vi behandla dina personuppgifter genom att vi skickar en förfrågan till BankID om att legitimera dig och
        utfärdaren av BankID kommer därefter självständigt behandla dina personuppgifter för att utföra en legitimationsåtgärd och sedan
        bekräfta din identitet till oss.
      </p>
      <p>
        Vår behandling av dina personuppgifter kan komma att ske till länder utanför EU/EES där ett lägre skydd för dina personuppgifter
        föreligger. I den utsträckning vi överför dina personuppgifter till ett land utanför EU/EES kommer vi vidta relevanta skyddsåtgärder
        för att säkerställa att en sådan överföring sker i enlighet med GDPR. Ett exempel på en sådan situation är att vår leverantör av
        molntjänster måste få tillhandahålla support från personal i exempelvis USA om vi efterfrågar sådan support under klockslag som
        motsvarar natt i EU/EES. Om personal från USA exempelvis utför support på vår Tjänst kommer vi att säkerställa att dina
        personuppgifter är krypterade och oläsliga för sådan personal samt att den arbetsgivare som personalen arbetar under har åtagit sig
        att behandla dina personuppgifter i enlighet med en relevant skyddsåtgärd som är godkänd enligt GDPR.
      </p>
      <h3>RÄTTSLIG FÖRPLIKTELSE OCH FÖREBYGGANDE AV SKADA</h3>
      <p>
        Utöver de legala grunder som vi tidigare uppgett i denna Integritetsnotis kan vi komma att behandla dina personuppgifter med
        rättslig förpliktelse som legal grund (exempelvis vid husrannsakan, domstolsbeslut, stämning eller liknande) eller när det är
        nödvändigt för att upptäcka, förhindra och adressera bedrägeri och annan brottslig aktivitet. vi kan även komma att behandla dina
        personuppgifter för att skydda intressen som är grundläggande för oss, dig och andra användare.
      </p>
      <p>
        Ovannämnd behandling kan exempelvis avse att besvara förfrågningar från brottsutredande myndigheter i jurisdiktioner utanför EU/EES
        när vi är i god tro att sådant svar krävs enligt lagen i den jurisdiktion där vi bedriver verksamhet eller berör användare i en
        sådan jurisdiktion, samt att ett sådant utlämnande är i enlighet med internationellt erkända överenskommelser.
      </p>
      <h3>DINA RÄTTIGHETER NÄR VI BEHANDLAR DINA PERSONUPPGIFTER I EGENSKAP AV PERSONUPPGIFTSANSVARIG</h3>
      <p>
        Du kan när som helst motsätta dig vår behandling av dina personuppgifter eller dra tillbaka ditt samtycke. Om du motsätter dig vår
        behandling eller drar tillbaka ditt samtycke påverkar detta inte lagligheten av tidigare behandling, och baserat på andra lagliga
        grunder kan vi komma att fortsätta behandla dina personuppgifter.
      </p>
      <p>
        Du har rätt att begära tillgång till och ytterligare information om vår behandling av dina personuppgifter, eller begära att vi
        korrigerar, rättar, kompletterar, raderar eller begränsar behandlingen av dina personuppgifter. Du har rätt att kostnadsfritt få en
        kopia av de personuppgifter som vi behandlar om dig. Begär du ytterligare kopior kan vi komma att ta ut en skälig avgift baserad på
        de administrativa kostnader en sådan förfrågan åsamkar oss. För behandling som är baserad på ditt samtycke har du rätt till
        dataportabilitet. Dataportabilitet innebär att du kan få ut de personuppgifter vi behandlar om dig, i ett strukturerat, allmänt
        använt och maskinläsbart format och har rätt att överföra sådana personuppgifter till en annan personuppgiftsansvarig.
      </p>
      <p>
        För att utöva rättigheterna enligt ovan eller om du har frågor om vår behandling av dina personuppgifter, dina rättigheter eller om
        du önskar att dina uppgifter ska raderas, vänligen kontakta oss på följande adress: bjorn.sundqvist@nhiab.com. För att vi effektivt
        ska kunna besvara din förfrågan, vänligen ange ditt fullständiga namn i ditt meddelande och, om tillämpligt, din adress,
        användarnamn och den e-postadress som användes vid registrering på vår Tjänst.
      </p>
      <p>
        Om du har några klagomål gällande vår behandling av dina personuppgifter, kan du skicka ett klagomål till relevant tillsynsmyndighet
        för dataskydd. Du kan hitta mer information om vilken som är din relevanta tillsynsmyndighet genom att klicka på följande länk:{' '}
        <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
          http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
        </a>
        .
      </p>
      <h3>MEDDELANDE OM ÄNDRINGAR I PERSONUPPGIFTSPOLICYN</h3>
      <p>
        Om vi ändrar något i denna personuppgiftspolicy meddelar vi dig via webbsida <a href="https://healfy.se">https://healfy.se</a> eller
        via e-post. Om ditt samtycke krävs på grund av förändringarna, kommer vi att skicka ett meddelande till dig och be om ditt samtycke
        i enlighet med gällande lag.
      </p>
      <div>
        <h3>VÅRA KONTAKTUPPGIFTER</h3>
        <p>
          Nordic Health Innovation AB
          <br />
          556878-3921l
          <br />
          Storgatan 20, 852 30 Sundsvall
          <br />
          <a href="mailto:info@nhiab.com">info@nhiab.com</a>
        </p>
      </div>
    </Section>
    <Footer theme="dark" page="privacy-policy" />
  </Layout>
)

export default PrivacyPolicyPage
