import React from 'react'
import logo from '../../images/healfy-logo-white.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'gatsby'
import HamburgerMenu from 'react-hamburger-menu'
import CheeseburgerMenu from 'cheeseburger-menu'

export class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { menuIsOpen: false }
  }

  openMenu = () => {
    this.setState({ menuIsOpen: true })
  }

  closeMenu = () => {
    this.setState({ menuIsOpen: false })
  }

  menuClicked = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen })
  }

  render() {
    const { external } = this.props
    return (
      <header>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center py-3">
            <div className="header__logo">
              <Link to="/">
                <img src={logo} alt="logo" />
              </Link>
            </div>
            <div className="desktop">
              <ul className="d-flex flex-row px-2">
                <li>
                  <Link to="/news">Nyheter</Link>
                </li>
                <li>
                  {external ? (
                    <Link to="/#hur-funkar-det">Så funkar HEALFY</Link>
                  ) : (
                    <AnchorLink href="#hur-funkar-det">Så funkar HEALFY</AnchorLink>
                  )}
                </li>
                <li>{external ? <Link to="/#om-oss">Om oss</Link> : <AnchorLink href="#om-oss">Om oss</AnchorLink>}</li>
                <li>
                  <a href="https://minasidor.healfy.se">Logga in</a>
                </li>
              </ul>
            </div>
            <div className="mobile">
              <CheeseburgerMenu
                //noShadow={true}
                topOffset="72px"
                backgroundColor="#23345e"
                width={300}
                right={true}
                isOpen={this.state.menuIsOpen}
                closeCallback={this.closeMenu}
              >
                <div>
                  <ul>
                    <li>
                      <Link to="/news" onClick={this.closeMenu}>
                        Nyheter
                      </Link>
                    </li>
                    <li>
                      {external ? (
                        <Link to="/#hur-funkar-det" onClick={this.closeMenu}>
                          Så funkar HEALFY
                        </Link>
                      ) : (
                        <AnchorLink href="#hur-funkar-det" onClick={this.closeMenu}>
                          Så funkar HEALFY
                        </AnchorLink>
                      )}
                    </li>
                    <li>
                      {external ? (
                        <Link to="/#om-oss" onClick={this.closeMenu}>
                          Om oss
                        </Link>
                      ) : (
                        <AnchorLink href="#om-oss" onClick={this.closeMenu}>
                          Om oss
                        </AnchorLink>
                      )}
                    </li>
                    <li>
                      <a href="https://minasidor.healfy.se">Logga in</a>
                    </li>
                  </ul>
                </div>
              </CheeseburgerMenu>
              <HamburgerMenu
                isOpen={this.state.menuIsOpen}
                menuClicked={this.menuClicked}
                width={28}
                height={20}
                strokeWidth={3}
                rotate={0}
                color="white"
                borderRadius={0}
                animationDuration={0.5}
              />
            </div>
          </div>
        </div>
      </header>
    )
  }
}

// export const Header = data => {
//   const { external } = data
//   return (
//     <header>
//       <div className="container">
//         <div className="d-flex justify-content-between py-3">
//           <div className="header__logo">
//             <Link to="/">
//               <img src={logo} alt="logo" />
//             </Link>
//           </div>
//           {/* <ul className="d-flex flex-column px-2">
//             <li>
//               {external ? (
//                 <Link to="/#hur-funkar-det">Så funkar HEALFY</Link>
//               ) : (
//                 <AnchorLink href="#hur-funkar-det">Så funkar HEALFY</AnchorLink>
//               )}
//             </li>
//             <li>{external ? <Link to="/#om-oss">Om oss</Link> : <AnchorLink href="#om-oss">Om oss</AnchorLink>}</li>
//             <li>
//               <a href="https://minasidor.healfy.se">Logga in</a>
//             </li>
//           </ul> */}
//         </div>
//       </div>
//     </header>
//   )
// }
